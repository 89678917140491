import styled from 'styled-components';

export const Root = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FullScreenRoot = styled.div`
  position: relative;
  height: 100%;
  width: fit-content;
  margin: 0 auto;
  z-index: 1;
`;

export const PaintLayer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const BottomLayer = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
`;

export const LeftBottom = styled.div`
  display: flex;
`;

export const ReportWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const UserMeetName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  margin: 10px;
`;

export const UserMeetNameSpan = styled.span`
  font-size: 2vh;
`;

export const VideoWrap = styled.div`
  text-align: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: #262525;
  border-radius: 20px;
  border: 3px solid #5a5a5a5e;
`;

export const VideoContainer = styled.div`
  position: absolute;
  max-width: 100%;
  height: inherit;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 5px;
`;

interface VideoProps extends React.HTMLAttributes<HTMLVideoElement> {
  mirror: boolean;
}

export const Video = styled.video<VideoProps>`
  transform: ${(props) => (props.mirror ? 'scaleX(-1)' : '')};
  width: 100%;
  height: 100%;
`;
