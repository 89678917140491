import { FC, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Slider,
} from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { getMeetRecord } from '../../api/meetRecords';
import { MeetRecord } from '../../api/models/meetRecords';
import { DEFAULT_RECORD } from './constants';
import Analytic from './Analytic';
import Logs from './Logs';
import { getSrcLink, getTimeFormSrc } from './utils';
import MergedVideo from './MergedVideo';
import { useHistory } from 'react-router-dom';

const Record: FC<void> = () => {
  const history = useHistory();
  const [record, setRecord] = useState<MeetRecord | null>(DEFAULT_RECORD);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlay, setIsPlay] = useState(false);
  const { recordId } = useParams<any>();

  const onChange = (e: any, newValue: any) => {
    setCurrentTime(newValue);

    const updateCurrentTime = (node: any) => {
      setCurrentTimeForNode(node, newValue);
    };
    document
      .querySelectorAll('#container video, #container audio')
      .forEach(updateCurrentTime);
  };

  const setCurrentTimeForNode = (
    node: HTMLVideoElement | HTMLAudioElement,
    currentTime: number
  ) => {
    if (!record) return;

    const nodeSrc = getSrcLink(node);
    const nodeTime = getTimeFormSrc(nodeSrc);
    const diff = nodeTime.diff(moment.utc(record.recordStartDate), 'seconds');
    const localTime = currentTime - diff;

    if (localTime < 0) {
      node.currentTime = 0;
      node.pause();
      node.style.display = 'none';
      return;
    }

    if (isFinite(node.duration) && localTime > node.duration) {
      node.currentTime = node.duration;
      node.pause();
      node.style.display = 'none';
      return;
    }

    node.currentTime = localTime;
    node.style.display = '';
    if (node.paused && isPlay) {
      node
        .play()
        .then(() => {})
        .catch(console.log);
    }
  };

  useEffect(() => {
    const a = (node: any) => {
      if (node.paused) {
        setCurrentTimeForNode(node, currentTime);
      }
    };
    document.querySelectorAll('#container video').forEach(a);
    document.querySelectorAll('#container audio').forEach(a);
  }, [currentTime]);

  const onSync = () => {
    const a = (node: any) => {
      setCurrentTimeForNode(node, currentTime);
    };
    document.querySelectorAll('#container video').forEach(a);
    document.querySelectorAll('#container audio').forEach(a);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPlay || !record) return;

      const elems = Array.from(
        document.querySelectorAll('#container video, #container audio')
      ).filter((x: any) => !x.paused);

      if (elems.length) {
        let max = -Number.MAX_VALUE;
        elems.forEach((node: any) => {
          const nodeSrc = getSrcLink(node);
          const nodeTime = getTimeFormSrc(nodeSrc);
          const diff = nodeTime.diff(
            moment.utc(record.recordStartDate),
            'seconds'
          );
          const localTime = node.currentTime + diff;
          if (max < localTime) max = localTime;
        });
        setCurrentTime(Math.round(max));
      } else {
        setCurrentTime((prev) => prev + 0.5);
      }

      //   const audios = document.querySelectorAll('audio');
      //   const videos = document.querySelectorAll('video');
      //   audios.forEach((audio) => {
      //     const src = getSrcLink(audio).split('/').slice(0, -1).join('/');
      //     Array.from(videos)
      //       .filter((video) => getSrcLink(video).startsWith(src))
      //       .forEach((video) => {
      //         video.play();
      //         video.currentTime = audio.currentTime;
      //       });
      //   });
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [isPlay, record]);

  useEffect(() => {
    getMeetRecord(recordId)
      .then((record) => {
        if (record) setRecord(record);
        else alert('Запись не найдена');
      })
      .catch(() => {
        history.push('/');
      });
  }, [recordId, history]);

  if (!record) return null;

  const duration = moment
    .utc(record.recordEndDate)
    .diff(moment.utc(record.recordStartDate), 's');

  const userIds = Object.keys(record.partialsRecords || {});

  const onTogglePlay = () => {
    setIsPlay((prev) => {
      if (prev) {
        document
          .querySelectorAll('#container video, #container audio')
          .forEach((x: any) => x.pause());
      }
      return !prev;
    });
  };

  return (
    <Container style={{ maxHeight: '100%', overflowY: 'scroll' }}>
      <div>
        <p>
          RoomId: <b>{record.roomId}</b> Date:{' '}
          {moment(record.recordStartDate).format('LLL')}
        </p>
        <p>Paticipants: {Object.keys(record.partialsRecords || {}).length}</p>
        <p>
          <a
            href={`https://app.progkids.com/i/gui/new-lessons/${record.roomId}`}
            target="_blank"
            rel="noreferrer"
          >
            Lesson Link
          </a>
        </p>
      </div>
      <br />
      <div>
        {userIds.map((userId) => {
          // @ts-ignore
          const pr = record.partialsRecords[userId];
          const { camera, screen, micro } = pr;

          const videoLinks = ([] as string[])
            .concat(camera?.links || [])
            .concat(screen?.links || []);

          return (
            <div key={userId} id="container">
              <p>{userId}</p>
              {videoLinks.map((link) => (
                <video
                  key={link}
                  width={500}
                  height={300}
                  preload="auto"
                  controls
                  className="controls"
                >
                  <source src={link} type="video/webm" />
                </video>
              ))}
              {(micro?.links || []).map((link) => (
                <audio key={link}>
                  <source src={link} type="audio/webm" />
                </audio>
              ))}
            </div>
          );
        })}
      </div>

      <div>
        <Button variant="contained" onClick={onTogglePlay}>
          {isPlay ? 'Stop' : 'Play'}
        </Button>

        <Button variant="contained" onClick={onSync}>
          Sync
        </Button>
      </div>

      <Slider
        min={0}
        max={duration}
        onChange={onChange}
        valueLabelDisplay="on"
        valueLabelFormat={() => moment.utc(currentTime * 1000).format('mm:ss')}
        value={currentTime}
        style={{ marginTop: 50 }}
      />

      <Accordion TransitionProps={{ timeout: 0 }}>
        <AccordionSummary>Meged Video</AccordionSummary>
        <AccordionDetails>
          <MergedVideo record={record} />
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ timeout: 0 }}>
        <AccordionSummary>JSON</AccordionSummary>
        <AccordionDetails>
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(record, null, 4)}
          </pre>
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ timeout: 0 }}>
        <AccordionSummary>Logs</AccordionSummary>
        <AccordionDetails>
          <Logs record={record} />
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ timeout: 0 }} expanded>
        <AccordionSummary>AI</AccordionSummary>
        <AccordionDetails>
          <Analytic recordId={recordId} />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default Record;
