import { useCallback, useEffect, useState } from 'react';

import Jitsi from 'lib-jitsi-meet';
import Bugsnag from '@bugsnag/js';

import { debug, logEvent } from '../../utils/debug';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setClients, setJitsiUserId, setStats } from '../../store/room';
import {
  addNewCameraTrack,
  addNewMicroTrack,
  getConnectionToJitsiServer,
  getLocalCameraTrack,
  getLocalScreenTrack,
  getRealDeviceId,
  getUserRegion,
} from './helpers';
import { roomSocket } from '../../socket';
import { ClientsState, Tracks, UseRoomProps } from './interfaces';

import { useHistory } from 'react-router-dom';
import { showErrorToast, showInfoToast } from '../../utils/toasts';
import {
  JitsiConference,
  JitsiLocalTrack,
  JitsiParticipant,
  JitsiStats,
} from '../../types/Jitsi';
import { NoiseSuppressionEffect } from '../../features/stream-effects/noise-suppression/NoiseSuppressionEffect';
import {
  toggleCamera,
  toggleMicro,
  toggleScreenSharing,
} from './toggleFunctions';

Jitsi.init({
  desktopSharingFrameRate: {
    max: 15,
  },
  screenShareSettings: {
    desktopDisplaySurface: 'monitor',
  },
});
Jitsi.setLogLevel(Jitsi.logLevels.ERROR);

const progkids: any = {
  Jitsi,
  room: null,
  connection: null,
};
// @ts-ignore
window.progkids = progkids;

const useRoom = ({ roomId }: UseRoomProps) => {
  const [isJitsiConnectionInit, setIsJitsiConnectionInit] = useState(true);
  const [jitsiRoom, setJitsiRoom] = useState<JitsiConference | undefined>();

  const [micDeviceId, setMicDeviceId] = useState('');
  const [camDeviceId, setCamDeviceId] = useState('');

  const history = useHistory();
  const {
    auth: {
      devices: { currentCamera, currentMicro, currentAudio },
      userId,
      user,
    },
    room: { clients, noiseSuppression, senderVideoQuality },
  } = useSelector((state: RootState) => state);
  const { localUser } = clients;
  const dispatch = useDispatch();

  const { countryByPhone, meetRegion } = user || {};
  const userRegion = getUserRegion(countryByPhone, meetRegion);

  const onConnectionEstablished = useCallback(
    (connection) => {
      debug('CONNECTION_ESTABLISHED');

      try {
        const room = connection.initJitsiConference(roomId, {
          deploymentInfo: { userRegion },
          p2p: { enabled: false },
        });
        room.setReceiverConstraints({
          lastN: 5,
          constraints: {},
          defaultConstraints: { idealHeight: 720, maxHeight: 720 },
        });
        const isElectron = navigator.userAgent.includes('Electro');
        room.setLocalParticipantProperty(
          'isElectron',
          JSON.stringify({ isElectron })
        );
        dispatch(setJitsiUserId(room.myUserId()));

        setIsJitsiConnectionInit(false);
        setJitsiRoom(room);
        progkids.room = room;
      } catch (error: any) {
        Bugsnag.notify(error);
        showErrorToast(`Ошибка: ${error.message}`);
      }
    },
    [dispatch, roomId, userRegion]
  );

  const onUpdateParticipants = useCallback(() => {
    if (!jitsiRoom) return;

    const onStageSources: string[] = [];
    const clients = jitsiRoom
      .getParticipants()
      .reduce<ClientsState>((acc, participant) => {
        const jitsiUserId = participant.getId();
        const tracks = participant.getTracks();

        const videoTracks = tracks.filter(
          (x) => x.type === 'video' && x.videoType === 'camera'
        );
        if (videoTracks.length > 1) {
          setTimeout(onUpdateParticipants, 1000);
        }

        const audioTrack = tracks.find((x) => x.type === 'audio');

        const readProp = (propName: string, defaultValue?: any) => {
          const propObject = JSON.parse(
            participant.getProperty(propName) || '{}'
          );
          return propObject[propName] ?? defaultValue;
        };

        if (readProp('isRecorder', false)) return acc;

        const participantProps = {
          userId: readProp('userId'),
          jitsiUserId,
          isElectron: readProp('isElectron', false),
          userMeetName: participant.getDisplayName(),
        };
        acc[jitsiUserId] = {
          ...participantProps,
          isScreenSharing: false,
          tracks: new Tracks(videoTracks[0], audioTrack),
        };

        const screenTrack = tracks.find((x) => x.videoType === 'desktop');
        if (screenTrack && !screenTrack.isMuted()) {
          onStageSources.push(screenTrack.getSourceName());
          acc[`${jitsiUserId}_sceen`] = {
            ...participantProps,
            isScreenSharing: true,
            tracks: new Tracks(screenTrack, null),
          };
        }

        return acc;
      }, {});

    jitsiRoom.setReceiverConstraints({ onStageSources });
    const localAudioTack = jitsiRoom.getLocalAudioTrack();
    const localVideoTack = getLocalCameraTrack(jitsiRoom);
    const localScreenTack = getLocalScreenTrack(jitsiRoom);

    dispatch(
      setClients((prev) => {
        const newClients: ClientsState = {
          ...clients,
          localUser: {
            ...prev.localUser,
            userId,
            tracks: new Tracks(localVideoTack, localAudioTack),
          },
        };
        if (localScreenTack && !localScreenTack.isMuted()) {
          newClients['localUser_screen'] = {
            ...prev.localUser,
            userId,
            isScreenSharing: true,
            tracks: new Tracks(localScreenTack, null),
          };
        }

        return newClients;
      })
    );
  }, [jitsiRoom, dispatch, userId]);

  const onUserLeft = useCallback((_, jitsiUser: JitsiParticipant) => {
    const { isRecorder } = JSON.parse(
      jitsiUser.getProperty('isRecorder') || '{}'
    );
    const name = jitsiUser.getDisplayName();
    if (!isRecorder) showInfoToast(`${name} вышел`);
  }, []);

  const leaveRoom = useCallback(async () => {
    logEvent({ event: 'leave_room' });
    if (jitsiRoom && jitsiRoom.isJoined()) {
      const tracks = jitsiRoom.getLocalTracks();
      await jitsiRoom.leave('progkids-unknown');
      await Promise.all(tracks.map((track) => track.dispose()));
    }
    await jitsiRoom?.connection.disconnect();

    // @ts-ignore
    const leaveRoom = window.leaveRoom;
    if (leaveRoom) {
      leaveRoom();
    } else {
      history.push('/');
      history.go(0);
    }
  }, [history, jitsiRoom]);

  const handleConnectRoom = () => {
    logEvent({ event: 'click_room_connect' });

    ['audio', 'video'].forEach((type) => {
      Jitsi.mediaDevices.isDevicePermissionGranted(type).then((value: any) => {
        logEvent({
          event: `permission_granted_${type}`,
          data: { value },
        });
      });
    });

    if (!jitsiRoom || jitsiRoom.isJoined()) return;

    jitsiRoom.setDisplayName(localUser.userMeetName);
    jitsiRoom.join('', true);

    roomSocket.io.opts.query = {
      roomId,
      userMeetName: localUser.userMeetName,
      userId,
      jitsiUserId: jitsiRoom.myUserId(),
    };
    roomSocket.connect();
  };

  const onDeviceChanged = useCallback(
    async (devices: MediaDeviceInfo[]) => {
      logEvent({ event: 'device_list_changed', data: { devices } });
      if (!jitsiRoom) return;

      const needNewTrack = (
        track: JitsiLocalTrack | undefined,
        oldId: string,
        newId: string
      ) => oldId === newId && (!track || track.isEnded() || track.disposed);

      setMicDeviceId((prev) => {
        const newId = getRealDeviceId(currentMicro, devices, 'audioinput');
        if (needNewTrack(jitsiRoom.getLocalAudioTrack(), prev, newId)) {
          logEvent({
            event: 'device_list_changed',
            data: { newId, prev, type: 'mic' },
          });
          addNewMicroTrack(jitsiRoom, newId);
        }

        return newId;
      });

      setCamDeviceId((prev) => {
        const newId = getRealDeviceId(currentCamera, devices, 'videoinput');
        if (needNewTrack(getLocalCameraTrack(jitsiRoom), prev, newId)) {
          addNewCameraTrack(jitsiRoom, newId);
          logEvent({
            event: 'device_list_changed',
            data: { newId, prev, type: 'cam' },
          });
        }

        return newId;
      });
    },
    [jitsiRoom, currentMicro, currentCamera]
  );

  // Create connection effect
  useEffect(() => {
    debug('INIT CONNECTION');

    const conn = getConnectionToJitsiServer();
    progkids.connection = conn;

    const { CONNECTION_ESTABLISHED } = Jitsi.events.connection;
    const establishedCallback = () => {
      conn.removeEventListener(CONNECTION_ESTABLISHED, establishedCallback);
      onConnectionEstablished(conn);
    };
    conn.addEventListener(CONNECTION_ESTABLISHED, establishedCallback);

    const { CONNECTION_FAILED, WRONG_STATE } = Jitsi.events.connection;

    const { CONNECTION_DROPPED_ERROR, SERVER_ERROR, OTHER_ERROR } =
      Jitsi.errors.connection;

    const onErrorCallback = (eventName: string, ...args: any) => {
      showErrorToast(`Проблема подключения: ${eventName}`);
      Bugsnag.notify(new Error(`CONNECTION_ERROR: ${eventName}`), (event) => {
        event.addMetadata('meta', { args });
      });

      setTimeout(() => history.go(0), 3000);
    };

    const removeEvents = [
      CONNECTION_FAILED,
      WRONG_STATE,
      CONNECTION_DROPPED_ERROR,
      SERVER_ERROR,
      OTHER_ERROR,
    ];
    removeEvents.forEach((eventName) =>
      conn.addEventListener(eventName, onErrorCallback)
    );

    roomSocket.on('FE-send-command', (data: any) => {
      if (data && data.command === 'show-info') {
        showInfoToast(data.message, {
          position: 'bottom-left',
          type: 'warning',
          theme: 'colored',
        });
      }
    });
    conn.connect({} as any);

    return () => {
      debug('DISPOSE INIT CONNECTION');
      setIsJitsiConnectionInit(false);
      conn.removeEventListener(CONNECTION_ESTABLISHED, establishedCallback);
      removeEvents.forEach((eventName) =>
        conn.removeEventListener(eventName, onErrorCallback)
      );

      conn.disconnect();
    };
  }, [onConnectionEstablished, history]);

  // add event handlers for
  useEffect(() => {
    if (!jitsiRoom) return;

    debug('ADD ROOM EVENTS');

    const { conference, connectionQuality } = Jitsi.events;

    const eventsForUpdate = [
      conference.PARTICIPANT_PROPERTY_CHANGED,
      conference.CONFERENCE_JOINED,
      conference.CONFERENCE_LEFT,
      conference.USER_LEFT,
      conference.USER_JOINED,
      conference.TRACK_ADDED,
      conference.TRACK_MUTE_CHANGED,
      conference.TRACK_REMOVED,
      conference.DOMINANT_SPEAKER_CHANGED,
    ];
    eventsForUpdate.forEach((event) =>
      jitsiRoom.on(event, onUpdateParticipants)
    );

    jitsiRoom.on(conference.USER_LEFT, onUserLeft);

    const onSetLocalStats = (stats: JitsiStats) => {
      dispatch(setStats({ id: 'localUser', stats }));
    };
    const onSetRemoteStats = (id: string, stats: JitsiStats) => {
      dispatch(setStats({ id, stats }));
    };
    jitsiRoom.on(connectionQuality.LOCAL_STATS_UPDATED, onSetLocalStats);
    jitsiRoom.on(connectionQuality.REMOTE_STATS_UPDATED, onSetRemoteStats);

    const callbacks = [
      conference.CONFERENCE_FAILED,
      conference.CONFERENCE_ERROR,
      conference.NO_AUDIO_INPUT,
      conference.NO_DATA_FROM_SOURCE,
      conference.TRACK_STREAMING_STATUS_CHANGED,
    ].map((name: string) => {
      const callback = (info: any) => {
        logEvent({
          event: 'conference_event',
          data: { name, info },
        });
      };

      jitsiRoom.on(name, callback);

      return { name, callback };
    });

    return () => {
      debug('REMOVE ROOM EVENTS');

      callbacks.forEach((item) => jitsiRoom.off(item.name, item.callback));
      jitsiRoom.off(connectionQuality.LOCAL_STATS_UPDATED, onSetLocalStats);
      jitsiRoom.off(connectionQuality.REMOTE_STATS_UPDATED, onSetRemoteStats);
      jitsiRoom.off(conference.USER_LEFT, onUserLeft);
      eventsForUpdate.forEach((event) =>
        jitsiRoom.off(event, onUpdateParticipants)
      );
    };
  }, [dispatch, jitsiRoom, onUserLeft, onUpdateParticipants]);

  useEffect(() => {
    const { DEVICE_LIST_CHANGED } = Jitsi.events.mediaDevices;
    Jitsi.mediaDevices.addEventListener(DEVICE_LIST_CHANGED, onDeviceChanged);

    return () => {
      Jitsi.mediaDevices.removeEventListener(
        DEVICE_LIST_CHANGED,
        onDeviceChanged
      );
    };
  }, [onDeviceChanged]);

  useEffect(() => {
    Jitsi.mediaDevices.enumerateDevices((devices: MediaDeviceInfo[]) => {
      const cameraId = getRealDeviceId(currentCamera, devices, 'videoinput');
      const microId = getRealDeviceId(currentMicro, devices, 'audioinput');
      logEvent({
        event: 'device_ids',
        data: { cameraId, microId, currentMicro, currentCamera },
      });
      setMicDeviceId(microId);
      setCamDeviceId(cameraId);
    });
  }, [currentMicro, currentCamera]);

  useEffect(() => {
    if (!jitsiRoom) return;
    addNewCameraTrack(jitsiRoom, camDeviceId);
  }, [camDeviceId, jitsiRoom]);

  // change micro device
  useEffect(() => {
    if (!jitsiRoom) return;
    addNewMicroTrack(jitsiRoom, micDeviceId);
  }, [micDeviceId, jitsiRoom]);

  const microTrack = jitsiRoom?.getLocalAudioTrack();
  useEffect(() => {
    if (noiseSuppression && microTrack)
      microTrack.setEffect(new NoiseSuppressionEffect()).catch(Bugsnag.notify);
  }, [noiseSuppression, microTrack]);

  // change audio device
  useEffect(() => {
    if (Jitsi.mediaDevices.getAudioOutputDevice() !== currentAudio) {
      Jitsi.mediaDevices
        .setAudioOutputDevice(currentAudio)
        .catch(Bugsnag.notify);
    }
  }, [currentAudio]);

  useEffect(() => {
    jitsiRoom?.setLocalParticipantProperty(
      'userId',
      JSON.stringify({ userId })
    );
  }, [jitsiRoom, userId]);

  const screenTrack = getLocalScreenTrack(jitsiRoom);
  useEffect(() => {
    if (!screenTrack) return;

    try {
      logEvent({
        event: 'set_screen_quality',
        data: { value: senderVideoQuality },
      });

      screenTrack.track.applyConstraints({
        height: {
          min: 360,
          ideal: senderVideoQuality,
          max: senderVideoQuality,
        },
      });
    } catch (error: any) {
      Bugsnag.notify(error);
    }
  }, [screenTrack, senderVideoQuality]);

  return {
    handleConnectRoom,
    toggleCamera: () => toggleCamera(jitsiRoom),
    toggleMicro: () => toggleMicro(jitsiRoom),
    toggleScreenSharing: () =>
      toggleScreenSharing(jitsiRoom, {
        maxFps: 10,
      }),
    leaveRoom,
    isInitialization: isJitsiConnectionInit,
    isJoined: jitsiRoom?.isJoined(),
  };
};

export default useRoom;
