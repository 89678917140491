import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getConnectionToJitsiServer } from '../hooks/useRoom/helpers';
import Jitsi from 'lib-jitsi-meet';
import { roomSocket } from '../socket';
import Bugsnag from '@bugsnag/js';
import { JitsiLocalTrack, JitsiParticipant } from '../types/Jitsi';

const { CONNECTION_ESTABLISHED } = Jitsi.events.connection;
const { TRACK_ADDED } = Jitsi.events.conference;

const Recorder = () => {
  let { roomId } = useParams<{ roomId: string }>();
  roomId = String(roomId).toLowerCase();

  useEffect(() => {
    const handleTrack = (track: JitsiLocalTrack) => {
      const getType = () => {
        if (track.type === 'video') {
          return track.videoType === 'camera' ? 'camera' : 'screen';
        }

        return 'micro';
      };

      const jitsiId = track.getParticipantId();
      const participant: JitsiParticipant =
        track.conference.getParticipantById(jitsiId);
      const recorder = new MediaRecorder(track.stream);

      const readProp = (propName: string, defaultValue?: any) => {
        const propObject = JSON.parse(
          participant.getProperty(propName) || '{}'
        );
        return propObject[propName] ?? defaultValue;
      };

      const getSocketOptions = () => ({
        roomId,
        userId: readProp('userId', undefined),
        type: getType(),
        jitsiId,
        trackId: track.getId(),
      });

      recorder.onstart = (e) => {
        roomSocket.emit('BE-record-start', getSocketOptions());
      };

      recorder.ondataavailable = (e) => {
        roomSocket.emit('BE-record-data', {
          ...getSocketOptions(),
          data: e.data,
        });
      };

      recorder.onstop = (e) => {
        roomSocket.emit('BE-record-stop', getSocketOptions());
      };

      recorder.onpause = (e) => {
        roomSocket.emit('BE-record-pause', getSocketOptions());
      };

      recorder.start(2000);
      if (track.type === 'audio') {
        const audio = new Audio();
        audio.srcObject = track.stream;
        audio.play();
      }
    };

    const conn = getConnectionToJitsiServer();
    conn.addEventListener(CONNECTION_ESTABLISHED, () => {
      const room = conn.initJitsiConference(roomId, {
        deploymentInfo: { userRegion: 'russia' },
        p2p: { enabled: false },
      });
      room.setLocalParticipantProperty(
        'isRecorder',
        JSON.stringify({ isRecorder: true })
      );
      room.setReceiverConstraints({
        lastN: 5,
        constraints: {},
        defaultConstraints: { idealHeight: 720, maxHeight: 720 },
      });
      room.setDisplayName('RECORDER');
      room.on(TRACK_ADDED, handleTrack);
      room.join('', true);
    });

    roomSocket.on('disconnect', (reason) => {
      Bugsnag.notify(new Error(`RECORDER DISCONNECT`), (event) => {
        event.addMetadata('meta', {
          reason,
          roomId,
          socketId: roomSocket.id,
          active: roomSocket.active,
        });
      });
    });

    roomSocket.io.opts.query = {
      roomId,
      userMeetName: 'RECORDER',
      userId: 'RECORDER',
      jitsiUserId: 'RECORDER',
    };
    roomSocket.connect();
    conn.connect({} as any);
  }, [roomId]);

  return null;
};

export default Recorder;
