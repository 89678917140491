import { Button, MenuItem, Select } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { MessageList } from 'react-chat-elements';
import { analyzeMeetRecord, getAiStats } from '../../api/meetRecords';
import { showErrorToast } from '../../utils/toasts';
import { unifyDialog } from './utils';

interface Props {
  recordId: string;
}

const Analytic: FC<Props> = ({ recordId }) => {
  const [aiStats, setAiStats] = useState<any>({});
  const [dialog, setDialog] = useState<any[]>([]);
  const [selectedDialogType, setSelectedDialogType] =
    useState('nanosemantic_audio');

  const loadAiStats = useCallback(() => {
    getAiStats(recordId)
      .then((data) => setAiStats(data[recordId]))
      .catch((e) => showErrorToast(e.message));
  }, [recordId]);

  useEffect(() => {
    loadAiStats();
  }, [loadAiStats]);

  const onGenerateTranscription = (type: string) => {
    analyzeMeetRecord(recordId, {
      types: [type],
      force: true,
    })
      .then(() => loadAiStats())
      .catch((e) => showErrorToast(e.message));
  };

  const sumVideoTime = (
    userVideoStats: any,
    state: 'Normal' | 'Distraction'
  ) => {
    if (userVideoStats.error) return 0;

    return (userVideoStats || [])
      .filter((x: any) => x.state === state)
      .reduce((acc: number, item: any) => acc + item.total_seconds, 0);
  };

  const videoStats = aiStats?.['nanosemantic_video']?.content || {};

  const vst = Object.keys(videoStats).map((userId) => {
    const userVideoStats = videoStats[userId];

    const normalState = sumVideoTime(userVideoStats, 'Normal');
    const distractionState = sumVideoTime(userVideoStats, 'Distraction');

    return {
      userId,
      normalState,
      distractionState,
    };
  });

  useEffect(() => {
    const messages = unifyDialog(
      aiStats?.[selectedDialogType]?.content || {},
      selectedDialogType
    );
    const { speaker } = messages[0] || {};
    setDialog(
      messages.map((x: any) => ({
        title: x.speaker,
        text: x.text,
        position: x.speaker === speaker ? 'left' : 'right',
        type: 'text',
        start: x.start,
      }))
    );
  }, [aiStats, selectedDialogType]);

  return (
    <div style={{ width: '100%' }}>
      <h3>Video Stats</h3>
      {vst.map((x) => (
        <p key={x.userId}>
          {`${x.userId} 😃: ${x.normalState.toFixed(
            0
          )} сек. 👺: ${x.distractionState.toFixed(0)} сек.`}
        </p>
      ))}

      <h3>GPT</h3>
      <pre style={{ whiteSpace: 'pre-wrap' }}>
        {JSON.stringify(aiStats.gpt, null, 4)}
      </pre>

      <h3>Dialog</h3>
      <div>
        <Select
          value={selectedDialogType}
          onChange={(e) => setSelectedDialogType(e.target.value as string)}
        >
          <MenuItem value="nanosemantic_audio">nanosemantic</MenuItem>
          <MenuItem value="nanosemantic_paid">nano_paid</MenuItem>
          <MenuItem value="whisper_free">whisper_free</MenuItem>
          <MenuItem value="whisper_paid">whisper_paid</MenuItem>
        </Select>
        <Button
          size="small"
          onClick={() => onGenerateTranscription(selectedDialogType)}
        >
          🥷
        </Button>
        {/* @ts-ignore */}
        <MessageList
          lockable={true}
          toBottomHeight={'100%'}
          dataSource={dialog}
        />
      </div>
      <div>
        <pre>{JSON.stringify(aiStats[selectedDialogType], null, 4)}</pre>
      </div>
    </div>
  );
};

export default Analytic;
