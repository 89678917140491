import { FC, useEffect, useState } from 'react';
import { getMergedVideo, mergedVideo } from '../../api/meetRecords';
import { MeetRecord } from '../../api/models/meetRecords';

interface Props {
  record: MeetRecord;
}

const MergedVideo: FC<Props> = ({ record }) => {
  const [link, setLink] = useState('');
  const [hasVideo, setHasVideo] = useState(false);

  useEffect(() => {
    if (!record._id) return;

    getMergedVideo(record._id).then((data) => {
      setLink(data.url);
      setHasVideo(data.hasVideo);
    });
  }, [record._id]);

  const onMergeClick = () => {
    if (!record._id) return;

    mergedVideo(record._id).then((data) => {
      alert('Record added into queue. To finish it can takes 15 minutes');
    });
  };

  return (
    <div>
      {hasVideo ? '  ✅  ' : '  🚫  '}
      <button onClick={onMergeClick}>merge</button>
      {hasVideo && (
        <a href={link} target="_blank" rel="noreferrer">
          Download
        </a>
      )}
      {link && (
        <video className="controls" width="100%" preload="auto" controls={true}>
          <source src={link} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

export default MergedVideo;
