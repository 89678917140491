import {
  Button,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import { FC, useState } from 'react';
import { MeetRecord } from '../../api/models/meetRecords';

import { Link } from 'react-router-dom';
import { DATE_FORMAT, STATUSES } from './constants';

import ViewIcon from '@mui/icons-material/Visibility';
import VideocamIcon from '@mui/icons-material/Videocam';
import {
  analyzeMeetRecord,
  getAiStats,
  getGptAnalytic,
} from '../../api/meetRecords';
import { showErrorToast } from '../../utils/toasts';

interface Props {
  record: MeetRecord;
}

const GPT_3_MOEL = 'gpt-3.5-turbo-1106';

const sumVideoTime = (userVideoStats: any, state: 'Normal' | 'Distraction') => {
  if (userVideoStats.error) return 0;

  return (userVideoStats || [])
    .filter((x: any) => x.state === state)
    .reduce((acc: number, item: any) => acc + item.total_seconds * 1000, 0);
};

const Row: FC<Props> = ({ record }) => {
  const [aiStats, setAiStats] = useState<any>({});
  const [selectedGptType, setSelectedGptType] = useState('nanosemantic_audio');
  const [selectedGptModel, setSelectedGptModel] = useState(GPT_3_MOEL);

  const loadAiStats = () => {
    getAiStats(record._id)
      .then((data) => setAiStats(data[record._id]))
      .catch((e) => showErrorToast(e.message));
  };

  const onGenerateGpt = () => {
    getGptAnalytic(record._id, {
      type: selectedGptType,
      gptModel: selectedGptModel,
    })
      .then((gpt) => loadAiStats())
      .catch((e) => showErrorToast(e.message));
  };

  const onGenerateTranscription = (type: string) => {
    analyzeMeetRecord(record._id, {
      types: [type],
      force: true,
    })
      .then(() => loadAiStats())
      .catch((e) => showErrorToast(e.message));
  };

  const duration = moment.duration(
    moment(record.recordEndDate).diff(moment(record.recordStartDate))
  );

  const participants = Object.keys(record.partialsRecords || {}).length;
  const isCompleted = record.status === 'UPLOADED_PARTIALS';

  const speachTime: any = {};
  const audioStats = aiStats['nanosemantic_audio'];
  if (audioStats) {
    Object.keys(audioStats.content).forEach((x) => {
      console.log(audioStats.content[x]);

      const time = (audioStats.content[x].phrases || []).reduce(
        (acc: number, item: any) => {
          return acc + (item.end - item.start);
        },
        0
      );

      speachTime[x] = moment.utc(time * 1000).format('mm:ss');
    });
  }

  const stats: any = {};
  Object.keys(record.partialsRecords || {}).forEach((key) => {
    const p = (record.partialsRecords || {})[key as any];
    const res = {
      camLength: p.camera?.links?.length || 0,
      screenLength: p.screen?.links?.length || 0,
      micLength: p.micro?.links?.length || 0,
    } as any;
    stats[key] = res;
  });

  const videoStats = aiStats?.['nanosemantic_video']?.content || {};

  const vst = Object.keys(videoStats).map((userId) => {
    const userVideoStats = videoStats[userId];

    const normalState = sumVideoTime(userVideoStats, 'Normal');
    const distractionState = sumVideoTime(userVideoStats, 'Distraction');

    return {
      userId,
      normalState,
      distractionState,
    };
  });

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          {record._id}
        </TableCell>
        <TableCell component="th" scope="row">
          {record.roomId}
        </TableCell>
        <TableCell component="th" scope="row">
          {moment(record.recordStartDate).format(DATE_FORMAT)}
        </TableCell>
        <TableCell component="th" scope="row">
          {record.recordEndDate
            ? moment(record.recordEndDate).format(DATE_FORMAT)
            : ''}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            color: duration.asMinutes() < 55 && isCompleted ? 'red' : 'black',
          }}
        >
          {duration.asMinutes().toFixed(0)}
        </TableCell>
        <TableCell component="th" scope="row">
          {
            // @ts-ignore
            STATUSES[record.status] || record.status
          }
        </TableCell>
        <TableCell component="th" scope="row">
          <IconButton
            component={Link}
            to={`/records/${record._id}`}
            target="_blank"
          >
            <ViewIcon />
          </IconButton>
          {!isCompleted && (
            <IconButton
              component={Link}
              to={`/view/${record.roomId}`}
              target="_blank"
            >
              <VideocamIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <span
            style={{
              color: participants < 2 && isCompleted ? 'red' : 'black',
            }}
          >
            Участники: {participants}
          </span>
          <br />
          Speach time:
          <pre>{JSON.stringify(speachTime, null, 4)}</pre>
          Stats:
          <pre>{JSON.stringify(stats, null, 4)}</pre>
          Video Stats
          {vst.map((x) => (
            <p key={x.userId}>
              {`${x.userId} 😃:  ${moment
                .utc(x.normalState)
                .format('mm:ss')} 👺: ${moment
                .utc(x.distractionState)
                .format('mm:ss')}`}
            </p>
          ))}
          <br />
          {Object.keys(aiStats).map((x) => {
            const isGpt = x === 'gpt';
            const { isExists } = aiStats[x] || {};

            return (
              <div key={x}>
                <Button
                  size="small"
                  onClick={
                    isGpt ? onGenerateGpt : () => onGenerateTranscription(x)
                  }
                >
                  🥷
                </Button>
                {x}: {isExists ? '✅ ' : '❌ '}
                {isGpt && (
                  <>
                    <Select
                      value={selectedGptType}
                      onChange={(e) =>
                        setSelectedGptType(e.target.value as string)
                      }
                    >
                      <MenuItem value="nanosemantic_audio">
                        nanosemantic
                      </MenuItem>
                      <MenuItem value="nanosemantic_paid">nano_paid</MenuItem>
                      <MenuItem value="whisper_free">whisper_free</MenuItem>
                      <MenuItem value="whisper_paid">whisper_paid</MenuItem>
                    </Select>
                    <Select
                      value={selectedGptModel}
                      onChange={(e) =>
                        setSelectedGptModel(e.target.value as string)
                      }
                    >
                      <MenuItem value={GPT_3_MOEL}>gpt-3.5</MenuItem>
                      <MenuItem value="gpt-4-1106-preview">gpt-4</MenuItem>
                    </Select>
                  </>
                )}
              </div>
            );
          })}
        </TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(aiStats?.gpt?.content, null, 2)}
          </pre>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
